<template>
  <div class="brandBoxs">
    <pcHeader />
    <div class="brandimg">
      <img src="../assets/brandbg.jpg" />
      <div class="banner-title">{{ $t("lang.brandCenter.bannerTitle") }}</div>
    </div>
    <div class="brandTitle">
      <div class="titles">{{ $t("lang.brandCenter.titles") }}</div>
      <div class="imgs">
        <img src="../assets/brandlogo.jpg" />
      </div>
    </div>
    <pcFooter />
  </div>
</template>
<script>
import pcHeader from "@/components/pcHeader";
import pcFooter from "@/components/pcFooter";
export default {
  components: {
    pcHeader,
    pcFooter,
  },
};
</script>
<style lang="scss">
.brandBoxs {
  .brandimg {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .banner-title {
      position: absolute;
      font-size: 55px;
      color: #fff;
      text-shadow: 0 0 25px #6d8fb7;
      font-weight: bold;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .brandTitle {
    width: 1200px;
    margin: 0 auto;
    margin-bottom: 40px;
    .titles {
      font-size: 26px;
      font-weight: bold;
      margin: 120px 0 30px;
    }
  }
}
</style>
